import React from "react";
import NameImage from "../images/text/solestielle_name_curved_navajo_white.svg";
import PhraseImage from "../images/text/for_humans_navajo_white.svg";

const LandingPage = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center" role="main" aria-labelledby="landing-page-header">
      {/* Name Image */}
      <div className="flex-grow">
        {/* NameImage is hidden on small-height and small-width screens */}
        <div
          className="fixed top-[10vh] left-[25vw] z-0 w-[15rem] md:block hidden h-[85vh] max-h-[400px]"
          aria-hidden="true"
        >
          <img
            src={NameImage}
            alt="Solestielle Logo"
            className="lg:block md:block sm:hidden h-[85vh] max-h-[100px]:hidden"
          />
        </div>

        {/* Phrase Image */}
        <div
          className="fixed top-[47vh] left-[35vw] z-0 w-[30vw] sm:w-[20vw] md:w-[30vw] lg:w-[30vw]"
          aria-hidden="true"
        >
          <img
            src={PhraseImage}
            alt="For humans."
            className="lg:block md:block sm:h-[10vh] max-h-[25px]:hidden"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
