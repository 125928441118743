import React, { useState, useRef, useEffect } from 'react';

function TermsOfService({ textColor }) {
  const [isOpen, setIsOpen] = useState(false);
  const closeButtonRef = useRef(null); // Ref for the close button

  // Close modal on Escape key press
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isOpen]);

  // Set focus on the close button when modal is open
  useEffect(() => {
    if (isOpen && closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, [isOpen]);

  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className={`${textColor} hover:underline focus:outline-none`}
        aria-haspopup="dialog"
        aria-expanded={isOpen}
      >
        Terms of Service
      </button>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
          role="dialog"
          aria-labelledby="terms-heading"
          aria-modal="true"
        >
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h2
              id="terms-heading"
              className="text-lg font-medium mb-4 text-left"
            >
              <strong>Terms of Service</strong>
            </h2>
            <div className="mb-4 text-left">
              <p>By using this site, you agree that:</p>
              <ul className="list-disc pl-5 mt-2">
                <li>all content is provided "as is" without warranty;</li>
                <li>emails sent to us may be used for business purposes;</li>
                <li>emailing us does not create a confidential relationship;</li>
                <li>you will not use our content without permission.</li>
              </ul>
            </div>
            <div className="text-right">
              <button
                ref={closeButtonRef}
                type="button"
                className="bg-federal-blue text-white px-4 py-2 rounded-md hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-federal-blue"
                onClick={() => setIsOpen(false)}
                aria-label="Close Terms of Service"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TermsOfService;
