import React, { useState } from "react";

const Contact = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="min-h-screen flex flex-col bg-lavender-web">
      <div className="flex flex-col items-start text-federal-blue flex-grow ml-[5vw] sm:ml-[10vw] md:ml-[15vw] lg:ml-[20vw]">
        <h1 className="text-4xl font-bold mb-6 mt-[20vw] md:mt-[15vw] lg:mt-[8vw] text-left font-georgia">
          Contact Us
        </h1>

        <div className="w-full text-left">
          <section className="mb-6 font-courier">
            <p className="text-lg" tabIndex="0">
              To reach out for any reason, please {" "}
              <a
                href="mailto:hello@solestielle.com"
                className="hover:underline relative"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                aria-label="Email us at hello@solestielle.com"
              >
                <strong>email us</strong>
                {isHovered && (
                  <span
                    className="absolute left-0 -bottom-6 bg-white text-federal-blue p-1 rounded text-sm"
                    role="tooltip"
                  >
                    hello@solestielle.com
                  </span>
                )}
              </a>
              !
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Contact;
