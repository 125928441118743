import React from "react";

const Products = () => {
  return (
    <div className="min-h-screen flex flex-col bg-medium-blue">
      <div className="flex flex-col items-start text-navajo-white flex-grow ml-[5vw] sm:ml-[10vw] md:ml-[15vw] lg:ml-[20vw]">
        <h1 className="text-4xl font-bold mb-6 mt-[20vw] md:mt-[15vw] lg:mt-[8vw] text-left font-georgia">
          Our Products
        </h1>
        <div className="w-full text-left">
          <section className="mb-6 font-courier">
            <p className="text-lg" tabIndex="0">
              Coming soon!
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Products;